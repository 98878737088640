<template>
  <div>
    <div class="container">
      <h4>Create Order</h4>
      <form v-on:submit.prevent="createOrder()">
        <div class="row">
          <div class="col-sm">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="sku">Email</span>
              </div>
              <v-select
                @input="getAllEmailData()"
                :options="countReq"
                v-model="mailSearch"
                label="email"
                style="width: 93%"
              >
                <template slot="option" slot-scope="option">
                  {{ option.email }} - ({{ option.count }})</template
                >
                <template slot="selected-option" slot-scope="option">
                  {{ option.email }}</template
                >
              </v-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="name">First Name</span>
              </div>
              <input
                type="text"
                aria-describedby="name"
                class="form-control"
                v-model="firstName"
                required
              />
            </div>
          </div>
          <div class="col">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="name">Last Name</span>
              </div>
              <input
                type="text"
                aria-describedby="name"
                class="form-control"
                v-model="lastName"
                required
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="img">Address 1</span>
              </div>
              <input
                type="text"
                class="form-control"
                aria-describedby="img"
                v-model="address1"
                required
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="linkProduct">Address 2</span>
              </div>
              <input
                type="text"
                class="form-control"
                aria-describedby="linkProduct"
                v-model="address2"
              />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="linkProduct">PostCode</span>
              </div>
              <input
                type="text"
                class="form-control"
                aria-describedby="linkProduct"
                v-model="postCode"
                required
              />
            </div>
          </div>
          <div class="col-sm-4">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="linkProduct">Country</span>
              </div>
              <v-select
                style="font-size: small; width: 74%"
                :required="!country"
                v-model="country"
                :options="countries"
                :reduce="(country) => country.code"
                label="name"
              >
                <template slot="option" slot-scope="option">
                  {{ option.name }} ({{ option.code }})
                </template>
                <template slot="selected-option" slot-scope="option">
                  {{ option.name }} ({{ option.code }})
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="quantity">City</span>
              </div>
              <input
                type="text"
                class="form-control"
                aria-describedby="quantity"
                v-model="city"
                required
              />
            </div>
          </div>
          <div class="col">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="price">State</span>
              </div>
              <input
                type="text"
                class="form-control"
                aria-describedby="price"
                v-model="state"
              />
            </div>
          </div>
          <div class="col">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="price">Phone</span>
              </div>
              <input
                type="text"
                class="form-control"
                aria-describedby="price"
                v-model="phone"
                required
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="price">Shipping</span>
                </div>
                <select
                  id="shippingCompany"
                  class="form-select"
                  v-model="shippingCompany"
                  required
                >
                  <option>Free Shipping</option>
                  <option>DHL</option>
                  <option>FedEx</option>
                  <option>CTT Expresso</option>
                  <option>CTT</option>
                  <option>Outro</option>
                </select>
              </div>
            </div>
            <div class="col">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="price"
                    >Shipping Value</span
                  >
                </div>
                <input
                  id="length"
                  type="number"
                  class="form-control"
                  v-model="shippingTotal"
                  step=".01"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">Comments</span>
              </div>
              <textarea
                class="form-control"
                aria-label="With textarea"
                v-model="comment"
              ></textarea>
            </div>
          </div>
        </div> -->
        <hr />
        <h5>Articles Order</h5>
        <table class="table">
          <tr>
            <th>SKU</th>
            <th>Name</th>
            <th>Qty</th>
            <th>Price</th>
            <th>Price Discount</th>
            <th>Total</th>
            <th></th>
          </tr>
          <tbody v-for="arti in articles" :key="arti.id">
            <tr>
              <td>{{ arti.sku }}</td>
              <td>{{ arti.name }}</td>
              <td>
                <input
                  class="form-control form-control-sm"
                  type="number"
                  v-model="arti.quantity"
                />
              </td>
              <td>{{ arti.price }}</td>
              <td>
                <input
                  class="form-control form-control-sm"
                  type="numerice"
                  v-model="arti.price_discount"
                />
              </td>
              <td>{{ (arti.price_discount * arti.quantity).toFixed(2) }}</td>
              <td>
                <button class="btn btn-danger" @click="removeProduct(arti.sku)">
                  -
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="text-align: right; margin-right: 20px">
          <b>Total:</b>
          {{
            articles
              .reduce((sum, article) => {
                return sum + article.price * discount * article.quantity;
              }, 0)
              .toFixed(2)
          }}
        </div>
        <div class="row">
          <div class="col">
            <button
              type="submit"
              class="btn btn-primary float-right"
              style="margin: 20px 0px"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
    <b-table :items="requests" :fields="fields" class="table">
      <template #cell(date)="data">
        <span v-if="data.item.date == null">-</span>
        <span v-else> {{ splitDate(data.item.date) }}</span>
      </template>
      <template #cell(sku)="data">
        <span v-if="data.item.product_id_backoffice != null">
          <!-- {{ data.item.our_sku[1] }} -->
          <span v-for="sku in data.item.our_sku" :key="sku"
            ><a :href="'/product/' + data.item.id_backoffice" target="_blank">
              {{ sku }}</a
            >
            <br /> </span
        ></span>
      </template>
      <template #cell(status)="data">
        <span v-if="data.item.status == 'pending'" class="pending">
          Pending</span
        ><span v-else-if="data.item.status == 'offer'" class="offer">
          Offer</span
        >
        <span v-else-if="data.item.status == 'canceled'" class="canceled"
          >Canceled</span
        >
        <span v-else-if="data.item.status == 'accepted'" class="accepted"
          >Accept</span
        >
        <span
          v-else-if="data.item.status == 'counter-offer'"
          class="counter-offer"
        >
          Counter Offer</span
        >
      </template>
      <template #cell(name)="data">
        <span v-if="data.item.product_id_backoffice != null">
          {{ data.item.our_name }}
        </span>
        <span v-else>{{ data.item.name }}</span>
      </template>
      <template #cell(price)="data">
        <span v-if="data.item.price == null">-</span>
        <span v-else>{{ data.item.price }}</span>
      </template>
      <template #cell(cancel)="data">
        <button class="btn btn-primary" @click="addProduct(data.item.id)">
          Add Prod.
        </button>
      </template>
      <template #cell(our_img)="data">
        <span v-if="data.item.our_img != null">
          <a :href="data.item.our_img" type="file" target="_blank"
            ><img
              :src="data.item.our_img"
              alt="Product Image"
              style="width: 100px; height: 100px"
            />
          </a>
        </span>
        <span v-else>- </span>
      </template>
      <template #cell(qty)="data">
        <span v-if="data.item.qty_sent == null">{{
          data.item.qty_accepted
        }}</span>
        <span v-else>{{ data.item.qty_accepted - data.item.qty_sent }}</span>
      </template>
      <template #cell(picking)="data">
        <span v-if="data.item.quantity_asked - data.item.quantity_allocated <= 0">✅</span>
        <span v-else>❌ <br> <b>{{ data.item.quantity_allocated }}</b> / <b>{{ data.item.quantity_asked }}</b>
        </span>
      </template>
      <template #cell(price_discount)="data">
        <span v-if="discount == 1">-</span>
        <span v-else>{{ Number(data.item.price * discount).toFixed(2) }}</span>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      requests: [],
      articles: [],
      address: [],
      mailSearch: "",
      countReq: [],
      countries: [],
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      phone: "",
      postCode: "",
      country: "",
      shippingTotal: 0,
      shippingCompany: "",
      backupRequests: [],
      fields: [
        {
          key: "id",
          label: "ID",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "date",
          label: "Date",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "our_img",
          label: "Img",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "sku",
          label: "SKU",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "name",
          label: "Prod. Name",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "qty_miss",
          label: "Qty",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "our_stock",
          label: "Stock",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "price",
          label: "Final Price",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "price_discount",
          label: "Discount Price",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "picking",
          label: "Picking",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        // {
        //   key: "price_counteroffer",
        //   label: "Price Counter Offer",
        //   tdClass: "align-middle",
        //   thStyle:
        //     "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        // },
        {
          key: "cancel",
          label: "Events",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
      ],
      discount: 1,
    };
  },
  methods: {
    async getAllEmailData() {
      await this.searchEmail();
      await this.filterRequests();
    },
    splitDate(date) {
      let split = date.split("T");
      return split[0];
    },
    async filterRequests() {
      try {
        await this.$store.dispatch("filterRequestsAndPicking", {
          email: this.mailSearch.email,
        });
        this.requests = this.getAllRequestsClient;
        this.requests.forEach((element) => {
          element.qty_miss = Number(element.qty_accepted) - element.qty_sent;
        });
        console.log(this.requests);
      } catch (error) {
        console.log(error);
      }
    },
    async addProduct(id) {
      let index = this.requests.findIndex((x) => x.id == id);
      //   {"[{\"id\":\"269594\",\"name\":\"Avène XeraCalm A.D Cleansing Oil Duo 2x400ml\",\"sku\":\"7248948\",\"product_id\":\"269594\",\"variation_id\":0,\"quantity\":2,\"tax_class\":\"\",\"price\":\"519.147\",\"subtotal\":\"1038.29\",\"subtotal_tax\":\"0.00\",\"total\":\"1038.29\",\"total_tax\":\"0.00\",\"taxes\":[],\"meta\":[]}]"}
      this.articles.push({
        id: this.requests[index].id_backoffice,
        name: this.requests[index].our_name,
        sku: this.requests[index].our_sku[0],
        product_id: this.requests[index].id_backoffice,
        variation_id: 0,
        quantity:
          Number(this.requests[index].qty_accepted) -
          Number(this.requests[index].qty_sent),
        tax_class: "",
        price: Number(this.requests[index].price),
        subtotal:
          this.requests[index].price *
          (Number(this.requests[index].qty_accepted) -
            Number(this.requests[index].qty_sent)),
        subtotal_tax: "0.00",
        total:
          this.requests[index].price *
          (Number(this.requests[index].qty_accepted) -
            Number(this.requests[index].qty_sent)),
        total_tax: "0.00",
        taxes: [],
        meta: [],
        id_request: this.requests[index].id,
        price_discount: Number(
          this.requests[index].price * this.discount
        ).toFixed(2),
      });
      console.log(this.articles);
      this.backupRequests.push(this.requests[index]);
      this.requests.splice(index, 1);
    },
    async searchEmail() {
      console.log(this.mailSearch.email);
      try {
        await this.$store.dispatch("searchClientAddressRequest", {
          email: this.mailSearch.email,
        });
        this.address = this.getSearchClientAddressRequest;
        this.firstName = this.address[0].first_name;
        this.lastName = this.address[0].last_name;
        this.city = this.address[0].city;
        this.state = this.address[0].state;
        this.phone = this.address[0].phone;
        this.address1 = this.address[0].address;
        this.address2 = this.address[0].address2;
        this.postCode = this.address[0].postcode;
        this.country = this.address[0].country;
        console.log(this.address);
      } catch (error) {
        console.log(error);
      }
      await this.getDiscount();
    },
    async countRequests() {
      try {
        await this.$store.dispatch("countEmailRequests");
        this.countReq = this.getCountRequestEmail;
        console.log(this.countReq);
      } catch (error) {
        console.log(error);
      }
    },
    async createOrder() {
      if (this.country != "") {
        let shippingLines = [];
        shippingLines.push({
          id: 1,
          method_title: this.shippingCompany,
          method_id: "flexible_shipping_single",
          total: this.shippingTotal,
          total_tax: 0.0,
          taxes: [],
        });
        this.$swal({
          title: "Create New Order?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
          cancelButtonText: "Cancel",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.articles.forEach((element) => {
              element.price = Number(element.price_discount).toFixed(
                2
              );
              element.subtotal =
                Number(element.quantity) * Number(element.price_discount);
              element.total = Number(element.quantity) * Number(element.price_discount);
            });
            let total = this.articles
              .reduce((sum, article) => {
                return sum + article.price * article.quantity;
              }, 0)
              .toFixed(2);
            try {
              await this.$store.dispatch("requestClientCreateOrder", {
                email: this.mailSearch.email,
                firstName: this.firstName,
                lastName: this.lastName,
                phone: this.phone,
                address1: this.address1,
                address2: this.address2,
                city: this.city,
                state: this.state,
                postCode: this.postCode,
                country: this.country,
                orderProducts: this.articles,
                shippingCompany: this.shippingCompany,
                total: total,
                shippingLines: shippingLines,
                backupProduct: this.backupRequests,
                exchange: "AED",
              });
              this.$swal({
                toast: true,
                icon: "success",
                title: "Order Created Successfully",
                showConfirmButton: false,
                timer: 3000,
                position: "top-end",
              });
              this.mailSearch = "";
              this.firstName = "";
              this.lastName = "";
              this.phone = "";
              this.address1 = "";
              this.address2 = "";
              this.city = "";
              this.state = "";
              this.postCode = "";
              this.country = "";
              this.articles = [];
              this.backupRequests = [];
            } catch (error) {
              alert(error);
            }
          }
        });
      } else {
        this.$swal({
          toast: true,
          icon: "error",
          title: "Please Select Country",
          showConfirmButton: false,
          timer: 3000,
          position: "top-end",
        });
      }
    },
    async removeProduct(id) {
      let index = this.articles.findIndex((x) => x.sku == id);
      console.log(index);
      this.requests.push(this.backupRequests[index]);
      this.backupRequests.splice(index, 1);
      this.articles.splice(index, 1);
    },
    async allCountries() {
      try {
        await this.$store.dispatch("fetchAllCountries");
        // for (let index = 0; index < this.getAllCountries.length; index++) {
        //   const element = this.getAllCountries[index];
        //   this.countries.push(`${element.code}`);
        // }
        for (let index = 0; index < this.getAllCountries.length; index++) {
          const element = this.getAllCountries[index];
          this.countries.push({
            name: element.name,
            code: element.code,
            coin: element.coin,
          });
        }
        console.log(this.countries);
      } catch (err) {
        alert(err);
      }
    },
    async getDiscount() {
      try {
        await this.$store.dispatch("clientDiscounts", {
          email: this.mailSearch.email,
        });
        console.log(this.getclientDiscount.discount);
        if (
          this.getclientDiscount.discount != null &&
          this.getclientDiscount.discount != undefined
        ) {
          this.discount = 1 - Number(this.getclientDiscount.discount) / 100;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.countRequests();
    this.allCountries();
  },
  computed: {
    ...mapGetters(["getAllRequestsClient"]),
    ...mapGetters(["getSearchClientAddressRequest"]),
    ...mapGetters(["getCountRequestEmail"]),
    ...mapGetters(["getAllCountries"]),
    ...mapGetters(["getclientDiscount"]),
  },
};
</script>
<style scoped>
.table {
  text-align: center;
}
.accepted {
  background-color: #538102;
  padding: 6px;
  border-radius: 8px;
  color: white;
  width: 100%;
}
h5 {
  text-align: center;
}
</style>
